import axios from 'axios';
import { Storage } from 'react-jhipster';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};

const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;

  try {
    const response = await axios.post(
      '/api/refresh-token',
      {
        refreshToken: Storage.local.get('refreshToken') || Storage.session.get('refreshToken'),
        rememberMe: false,
      },
      {
        headers: { Authorization: undefined },
      }
    );

    const newToken = response.data?.accessToken;
    if (newToken) {
      Storage.local.set('jhi-authenticationToken', newToken);
      Storage.session.set('jhi-authenticationToken', newToken);
      Cookies.set('jhi-authenticationToken', newToken);

      processQueue(null, newToken);
      return newToken;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Refresh token failed:', error);
    processQueue(error, null);

    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    Cookies.remove('jhi-authenticationToken');

    return null;
  } finally {
    isRefreshing = false;
  }
};

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token =
      Storage.local.get('jhi-authenticationToken') ||
      Cookies.get('jhi-authenticationToken') ||
      Storage.session.get('jhi-authenticationToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  const onResponseSuccess = response => response;

  const onResponseError = async error => {
    const status = error.status || (error.response ? error.response.status : 0);

    if (status === 401 && !error.config._retry) {
      error.config._retry = true;
      console.log('Token expired — attempting refresh');

      localStorage.removeItem('jhi-authenticationToken');
      sessionStorage.removeItem('jhi-authenticationToken');
      Cookies.remove('jhi-authenticationToken');

      const newToken = await refreshToken();

      if (newToken) {
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return axios.request(error.config);
      } else {
        delete axios.defaults.headers.common['Authorization'];
        onUnauthenticated();
        toast.error('Session expired. Please log in again.');
        onUnauthenticated();

          if (Storage.local.get('refreshToken') || Storage.session.get('refreshToken')) {
          window.location.href = '/';
        }
      }
    }

    return Promise.reject(error);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
